import { navigateToUrl } from 'single-spa';
import { urlApp } from '../utils/url';

(function () {
  'use strict';

  /**
   * @ngdoc function
   * @name app.directive:audienceController
   * @audienceController controller
   * # audienceController
   * audience builder controller
   */

  angular.module('app')
    .controller('audienceController', ['$scope', '$rootScope', '$uibModal', '$filter', 'config', 'hub', 'authService', 'campaignService', 'tableService', 'dialogService', 'featureService', '$location', 'accountService', '$routeParams', '$route', 'copyService', 'pixelService', 'logger', 'timezoneService', 'serverTableService', function ($scope, $rootScope, $modal, $filter, config, hub, authService, campaignService, tableService, dialogService, featureService, $location, accountService, $routeParams, $route, copyService, pixelService, logger, timezoneService, serverTableService) {

      if ($location.path().indexOf('/audience') > -1)
        navigateToUrl(urlApp('app/audience'))

      var log = hub.getLog('Audience Management');
      var vm = this;
      vm.log = log;
      vm.config = hub.config;
      vm.warn = hub.getLog('Audience Management', 'warn');
      vm.sts = serverTableService.getInstance({ keyword: '', audienceSubType: null });
      vm.ts = tableService;
      var rootVM = $rootScope.rootVM;
      vm.sort = {
        audiences: { pageNumber: 1, column: 'createdDate', sortDesc: true, keyword: '' },
        marketerCampaigns: { pageNumber: 1, column: 'createdDate', sortDesc: true, keyword: '' }
      };
      vm.webEventsPage = $location.path().indexOf('web-events') > -1;
      vm.isObserverRole = authService.isObserverRole();

      vm.account = accountService.get();
      //disable Audience download if opt=12500 is set
      var downloadOpt = vm.account && vm.account.options ? vm.account.options.find(f => f.optionId === 12500) : null;

      hub.page.setTitle('Audiences');
      vm.hasAccess = authService.hasAccess;
      vm.isObserver = authService.isObserver;
      vm.canEdit = (authService.isSuperAdmin() || authService.hasAccess('audiences', 'Manage')) && !vm.isObserverRole;
      vm.isAdmin = authService.isSuperAdmin();
      vm.hasDealsAccess = authService.hasAccess('deals', 'List');
      vm.page = hub.page;
      vm.config = hub.config;

      vm.currentEastTimeAbbreviation = timezoneService.getInfo().currentEastTimeAbbreviation;

      vm.canRemove = function () {
        return vm.isAdmin;
      }

      const isAdminOnlyRoute = $route.current.originalPath.indexOf("/activation/admin-only") > -1;
      vm.isAdminOnlyRoute = isAdminOnlyRoute;
      const webEvents = vm.webEventsPage ? '/web-events' : '';
      vm.tabs = [
        // Can't delete unused tabs, they are referenced by index
        {
          active: false, title: 'Custom', location: 'activation/audience',
          type: 'Custom', editTemplate: "",
          adminOnly: false,
        },
        {
          active: false, title: 'Lasso Pixels', location: `activation${webEvents}/pixel`,
          type: 'Conversion', editTemplate: "views/audience/editors/pixel-editor.html",
          adminOnly: false,
        },
        {
          active: false, title: 'Admin-only', location: `activation${webEvents}/admin-only`,
          type: 'AdminOnly', editTemplate: "views/audience/editors/admin-only-editor.html",
          adminOnly: true,
        },
        {
          active: false, title: 'Retargeting', location: `activation${webEvents}/retargeting-audience`,
          type: 'retargeting', editTemplate: "views/audience/editors/retargeting-audience-editor.html",
          adminOnly: false,
        },
        {
          active: false, title: 'Deals', location: `admin${webEvents}/deals`,
          type: 'AdminOnly', editTemplate: "views/audience/editors/deal-editor.html",
          adminOnly: true,
        },
      ];

      vm.adminOnlyTabs = [
        {
          active: true, title: 'External Audience', type: 'ExternalAudience'
        },
        {
          active: false, title: 'CMI Audience', type: 'CMIAudience'
        },
        {
          active: false, title: 'CMI Campaign', type: 'CMICampaign'
        },
        {
          active: false, title: 'CMI Proact Creative Mapping (file upload only)', type: 'CMIPROACTCampaign'
        },
        {
          active: false, title: 'ConneXion360', type: 'ConneXion360'
        },
        {
          active: false, title: 'Deals', type: 'Deals'
        },
        {
          active: false, title: 'Global Xandr DMP Segment Push', type: 'GlobalXandrDMPSegmentPush'
        }
      ];

      vm.getActiveTab = function () {
        return vm.tabs.find((t) => t.active);
      }

      vm.currentType = function () {
        const tab = vm.getActiveTab();
        return tab ? tab.type : null;
      }
      vm.canDownload = (audience) => {
        return (!downloadOpt || !downloadOpt.enabled) && audience.dataSourceId != 4;
      };

      vm.setActiveTab = function (tabIndex) {
        if (vm.webEventsPage && tabIndex < 1) {
          tabIndex = 1;
        }
        if (vm.sts.filter.keyword.length > 0) {
          vm.sts.filter.keyword = "";
        }

        const activeIndex = vm.tabs.findIndex((t) => t.active);
        vm.tabs.forEach((tabItem, index) => {
          tabItem.active = tabIndex === index;
          if (tabItem.active) {
            $location.path(tabItem.location, false);
            vm.activeTab = index;
          }
        });
        if (activeIndex != tabIndex) {
          rootVM.audiences = null;
        }
        vm.sts.reset();
        vm.sts.filter = {
          keyword: vm.sts.filter.keyword
        };
        if (vm.adminOnlyActiveTab > 0 && tabIndex == 2)
          vm.setActiveAdminOnlyTab(0);
        else
          vm.loadData();
      };

      vm.setActiveAdminOnlyTab = function (tabIndex) {
        var activeAdminOnlyIndex = vm.adminOnlyTabs.findIndex((t) => t.active);
        vm.adminOnlyTabs.forEach((tabItem, index) => {
          tabItem.active = tabIndex === index;
          if (tabItem.active) {
            vm.adminOnlyActiveTab = index;
          }
        });
        if (activeAdminOnlyIndex != tabIndex) {
          rootVM.audiences = null;
          vm.marketerCampaigns = null;
          vm.audiences = null;

        }
        let customFilter = {};
        if (tabIndex === 0) {
          customFilter = { showInternal: false };
        } else if (tabIndex === 1) {
          customFilter = { hasMeta: true, marketerId: 49 };
        } else if (tabIndex === 2) {
          customFilter = { marketerId: 49 };
        } else if (tabIndex === 4) {
          customFilter = { marketerId: 78 };
        } else if (tabIndex === 6) {
          customFilter = { showInternal: true };
        }

        vm.sts.filter = {
          keyword: vm.sts.filter.keyword,
          ...customFilter
        };
        if ((tabIndex === 0 || tabIndex === 1 || tabIndex === 6) && !vm.audiences || (tabIndex === 2 || tabIndex === 4) && !vm.marketerCampaigns)
          vm.loadData(false, true);
      }

      vm.getActiveAdminOnlyTab = function () {
        return vm.adminOnlyTabs.find((t) => t.active);
      }

      vm.copyTag = function (pixel) {
        copyService.copy(pixel);
      }

      vm.showId = true;
      vm.showAdvertiserName = featureService.isActive('ADVERTISERNAME');

      vm.getAudienceType = function (id) {
        return vm.audienceTypes.find(function (at) {
          return at.id === id;
        }).name;
      };

      vm.sendMessage = function (l) {
        return hub.api.get('api/audience/SendAudienceMessage/' + l.id).then(function (r) {
          hub.getLog('Audience Management', 'info')('Syncronization message for ' + l.name + ' has been sent to exchange-integration');
          l.messageSent = true;
        });
      };

      vm.audienceTypes = campaignService.getAudienceTypes();
      vm.audienceStatuses = campaignService.getAudienceStatuses();
      vm.channels = campaignService.getChannels();
      vm.getChannelById = function (channelId) {
        var name = '';
        vm.channels.forEach(function (item) {
          if (item.id === channelId)
            name = item.name;
        });
        return name;
      };

      vm.getAudienceStatus = function (statusId) {
        var status = vm.audienceStatuses.find(function (as) {
          return as.id === statusId;
        });
        if (status)
          return status.name;
        else
          return "";
      };

      $scope.updatePageData = function () {
        const webAudiences = vm.audiences.filter(a => a.audienceType === 'retargeting' || a.audienceType === 'Web' || a.audienceType === 'Conversion');
        campaignService.getAccounts(1).then(function () {
          for (let audience of webAudiences) {
            var advertiser = campaignService.advertisers.find(function (a) {
              return a.id == audience.advertiserId;
            });
            if (advertiser)
              audience.advertiserName = advertiser.name;
            if (audience.audienceType === 'Web' || audience.audienceType === 'Conversion') {
              pixelService.setJsPixel(audience);
              pixelService.setImgPixel(audience);
            }
          }
        });

      };

      $scope.hover = function (entity, show) {
        // Shows/hides the delete button on hover
        return entity.showActions = show;
      };

      $scope.search = function (item) {
        if (!vm.sort.audiences.keyword || (item.name.toLowerCase().indexOf(vm.sort.audiences.keyword.toLowerCase()) != -1) || (item.marketer.toLowerCase().indexOf(vm.sort.audiences.keyword.toLowerCase()) != -1) || (item.advertiser.toLowerCase().indexOf(vm.sort.audiences.keyword.toLowerCase()) != -1)) {
          return true;
        }
        return false;
      };

      vm.sortFn = function (column, list) {
        if (list === 'audiences') {
          vm.sts.sortFn(column);
        } else {
          vm.ts.sortFn(column, list);
          vm.sort[list].column = vm.ts.sort[list].column;
          vm.sort[list].sortDesc = vm.ts.sort[list].sortDesc;
          vm.sort[list].keyword = vm.ts.sort[list].keyword;
        }
      };

      vm.editAudience = function (audience, viewMode) {
        if (!viewMode)
          viewMode = 'edit';
        const activeTab = vm.getActiveTab();
        const type = activeTab.type;
        const location = activeTab.location;
        var modalInstance;
        const tab = vm.getActiveTab();
        campaignService.getAccounts(2).then(function () {
          modalInstance = $modal.open({
            templateUrl: tab.editTemplate,
            animation: true,
            controller: type === 'AdminOnly'
              ? 'editAdminOnlyController'
              : (type === 'Conversion'
                  ? 'editConversionPixelController'
                  : 'editRetargetingAudienceController'
              ),
            size: 'lg',
            backdrop: 'static',
            resolve: {
              selectedAdminOnlyAudienceType: function () {
                return vm.getActiveAdminOnlyTab();
              },
              adminOnlyAudienceTypes: function () {
                return vm.adminOnlyTabs.filter(x => x.type !== 'GlobalXandrDMPSegmentPush');
              },
              data: function () {
                if (!audience) {
                  campaignService.setSelectedMarketerId('');
                  var advertiserId = null;
                  var marketerId = null;
                  if (vm.account) {
                    if (vm.account.accountType == 1)
                      advertiserId = vm.account.id;
                    else if (vm.account.accountType == 2)
                      marketerId = vm.account.id;
                  }
                  return {
                    type: type,
                    name: '',
                    marketerId: marketerId,
                    advertiserId: advertiserId,
                    marketer: '',
                    recencyType: '',
                    channelId: '',
                    retargetingAction: type === 'retargeting' ? 1 : undefined,
                    includedGroups: [],
                    includedBrandSafetySegments: [],
                    deviceTypes: [1, 2, 4],
                    placements: [1, 2, 4],
                    countries: ["US"],
                    selectedLocations: [],
                    audienceLoyality: [false, false, false, false, false, false, false, false, false],
                    showImpsPerUserPerDay: true,
                    impsPerUserPerDay: 2,
                    excludeMyAdvertiserCustomers: false,
                    browsers: true,
                    buyers: true,
                    recencyFrom: 0,
                    recencyTo: 30,
                  };
                } else if (!audience.includedGroups) {
                  audience.includedGroups = [];
                  audience.type = type;
                  return audience;
                } else {
                  audience.type = type;
                  return audience;
                }
              },
              viewMode: function () {
                return viewMode;
              }
            }
          });
          modalInstance.result.then((function (audience) {
            vm.log(audience.name + " Saved");
            vm.audiences = $rootScope.rootVM.audiences;
            if (type === 'AdminOnly')
              vm.loadData(null, true);
            $scope.updatePageData();
            if (type !== 'AdminOnly')
              $location.path(location, false);
          }), (function () {
            if (type !== 'AdminOnly')
              $location.path(location, false);
          }));
        });
      };

      vm.copyAudience = function (audience) {
        var startTime = new Date();
        if (!rootVM.accountId) {
          vm.copyAudienceAcrossAdvertisers(audience);
        } else {
          hub.webapicontext.copyAudience(audience.id).then(function () {
            vm.log(audience.name + " Copied");
            const currentType = vm.currentType();
            hub.webapicontext.getAudiences(currentType, 0, true).then(function (d) {
              var data = { id: audience.id, name: audience.name };
              authService.trackEvent({
                action: 'CopyAudience',
                view: $location.$$path,
                data: JSON.stringify(data)
              }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
              rootVM.audiences = d;
              vm.audiences = rootVM.audiences;
              vm.ts.setData(vm.audiences, 'audiences', vm.sort['audiences']);
              vm.ts.search('audiences');
              $scope.updatePageData();
            });
          });
        }
      };

      vm.copyAudienceAcrossAdvertisers = function (audience) {
        var modalOptions = {
          templateUrl: 'views/audience/editors/copy-audience.html',
          animation: false,
          controller: 'copyAudienceController',
          size: 'md',
          resolve: { audience: audience }
        };
        dialogService.showModal({ windowClass: 'modal-dialog-alert' }, modalOptions).then(function (result) {
          var startTime = new Date();
          hub.webapicontext.getAudiences('Custom', 0, true).then(function (d) {
            var data = { id: audience.id, name: audience.name };
            authService.trackEvent({
              action: 'CopyAudienceAcrossAdvertisers',
              view: $location.$$path,
              data: JSON.stringify(data)
            }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
            rootVM.audiences = d;
            vm.audiences = rootVM.audiences;
            vm.ts.setData(vm.audiences, 'audiences', vm.sort['audiences']);
            vm.ts.search('audiences');
            $scope.updatePageData();
          });
          vm.log(audience.name + " Copied across " + audience.advertiser);
        });
      };

      vm.removeAudience = function (audience) {
        var modalOptions = {
          closeButtonText: 'Cancel',
          actionButtonText: 'Remove',
          message: 'Are you sure you want to remove audience ' + audience.name + '?'
        };
        dialogService.showModal({ windowClass: 'modal-dialog-alert' }, modalOptions).then(function (result) {
          var startTime = new Date();
          hub.webapicontext.removeAudience(audience.id).then(function () {
            vm.log(audience.name + " Removed");
            var data = { id: audience.id, name: audience.name };
            authService.trackEvent({
              action: 'RemoveAudience',
              view: $location.$$path,
              data: JSON.stringify(data)
            }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
            $rootScope.$broadcast('audienceSaved', audience);
          });
        });
      };

      vm.pushAudience = function (audience) {
        var modalOptions = {
          templateUrl: 'views/audience/editors/push-audience.html',
          animation: false,
          controller: 'pushAudienceController',
          size: 'md',
          resolve: {
            data: function () {
              return audience;
            }
          }
        };
        dialogService.showModal({ windowClass: 'modal-dialog-alert' }, modalOptions).then(function (result) {
          if (result && result.message)
            vm.log(result.message);
          else {
            vm.log(audience.name + " Pushed");
            vm.loadData(false, true);
          }
        });
      };

      var original = $location.path;
      $location.path = function (path, reload) {
        if (reload === false) {
          var lastRoute = $route.current;
          var un = $rootScope.$on('$locationChangeSuccess', function () {
            if (IsCurrentPage($route.current.$$route.originalPath))
              $route.current = lastRoute;

            un();
          });
        }
        return original.apply($location, [path]);
      };

      function getTabsLocations() {
        return vm.tabs.filter(x => x.adminOnly === vm.adminOnlyTabs).map(t => t.location);
      }

      function IsCurrentPage(path) {
        const locations = getTabsLocations();
        const hasLocation = locations.some((location) => path.indexOf(location) > -1);
        return hasLocation;
      }

      vm.processUrl = function () {
        const isCurrent = IsCurrentPage($location.$$path);
        if (isCurrent) {
          var audienceId = $routeParams.audienceId;
          if (audienceId) {
            var audience = vm.audiences.find(function (a) {
              return a.id == audienceId;
            });
            vm.editAudience(audience, (vm.isObserver('audiences', audience) ? 'readonly' : 'edit'));
          } else if (vm.canEdit && $location.$$path.indexOf('/new') > -1) {
            vm.editAudience(null, 'edit');
          } else {
            $rootScope.$broadcast('modal.force.close', null);
          }
        }
      };

      vm.getDownloadUrl = function (id, notMatched) {
        return hub.api.get('api/audience/download' + (notMatched ? "matcherrors" : "") + '/' + id + (notMatched ? "" : "/false")).then(function (r) {
          if (r.error) {
            vm.warn(r.error);
          } else if (r.url) {
            var a = document.createElement('a');
            a.href = r.url;
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
          }
        });
      };

      vm.getDownloadJsPixel = function (audience) {
        pixelService.downloadPixel(audience.name, audience.tag, 'js');
      }

      vm.getDownloadImgPixel = function (audience) {
        pixelService.downloadPixel(audience.name, audience.imgTag, 'img');
      }

      vm.getDownloadConvSegPixels = function (audience) {
        pixelService.downloadPixel(audience.name, audience.convSegTag, 'img');
      }

      vm.loadData = function (process = true, force = false) {
        if (!rootVM.audiences || force) {
          var currentType = vm.currentType();
          var activeTab;
          if (currentType === 'AdminOnly') {
            activeTab = vm.getActiveAdminOnlyTab();
            if (!activeTab)
              activeTab = vm.adminOnlyTabs[0];
          }
          if (!activeTab || activeTab.type === 'ExternalAudience' || activeTab.type === 'CMIAudience' || activeTab.type === 'GlobalXandrDMPSegmentPush') {
            if (!vm.sts.pagedSearchPromise) {
              const cb = () => {
                const pager = {
                  pageSize: vm.sts.pager.pageSize,
                  pageNumber: vm.sts.pager.pageNumber,
                  sortDescending: vm.sts.sort.sortDesc,
                  sortName: vm.sts.sort.sortName
                };
                const getType = () => {
                  var currentType = vm.currentType();
                  var activeTab;
                  if (currentType === 'AdminOnly') {
                    activeTab = vm.getActiveAdminOnlyTab();
                    if (!activeTab)
                      activeTab = vm.adminOnlyTabs[0];
                    currentType = 'Custom';
                    if (activeTab.type === 'ExternalAudience') {
                      currentType = '';
                      vm.sts.filter.showInternal = false;
                    }
                    if (activeTab.type === 'GlobalXandrDMPSegmentPush') {
                      currentType = '';
                      vm.sts.filter.showInternal = true;
                      vm.sts.filter.isPublicFilter = true;
                    }
                  } else if (currentType === 'Custom') {
                    vm.sts.filter.showInternal = true;
                  }
                  return currentType;
                }
                const audienceType = getType();
                const flt = {
                  hasMeta: vm.sts.filter.hasMeta,
                  audienceType,
                  marketerId: vm.sts.filter.marketerId,
                  isAudiencePage: true,
                  channelId: null,
                  advertiserId: null,
                  keyword: vm.sts.filter.keyword,
                  showInternal: vm.sts.filter.showInternal,
                  isPublicFilter: vm.sts.filter.isPublicFilter
                };
                return hub.webapicontext.searchAudiences(flt, pager)
                  .then(data => {
                    rootVM.audiences = data.list;
                    vm.audiences = rootVM.audiences;
                    campaignService.getAccounts().then(function () {
                      vm.audiences.forEach(function (a) {
                        var marketer = campaignService.marketers.find(function (m) {
                          return m.id === a.marketerId
                        })
                        a.marketerName = marketer ? marketer.name : '';
                        var advertiser = campaignService.advertisers.find(function (m) {
                          return m.id === a.advertiserId
                        })
                        a.advertiserName = advertiser ? advertiser.name : '';
                      });
                    });
                    $scope.updatePageData();
                    if (process) {
                      vm.processUrl();
                    }
                    return data;
                  });
              }
              vm.sts.setPagedSearchPromise(cb);
            }
            vm.sts.search();
          } else if (activeTab.type === 'CMICampaign' || activeTab.type === 'ConneXion360')
            hub.webapicontext.getMarketerCampaigns().then(function (d) {
              vm.marketerCampaigns = d;
              campaignService.getCampaignsList().then(function () {
                vm.campaigns = campaignService.campaigns;
                vm.marketerCampaigns.forEach(function (mc) {
                  vm.campaigns.forEach(function (c) {
                    if (c.campaignId == mc.campaignId) {
                      mc.marketerId = c.marketerId;
                      mc.advertiserId = c.advertiserId;
                      mc.advertiserName = c.advertiserName;
                      mc.campaignName = c.name;
                    }
                  });
                });
                if (vm.sts.filter.keyword) {
                  vm.marketerCampaigns = vm.marketerCampaigns.filter(mc => mc.campaignName.findIndex(vm.sts.filter.keyword) > -1);
                }
                if (vm.sts.filter.marketerId) {
                  vm.marketerCampaigns = vm.marketerCampaigns.filter(mc => mc.marketerId == vm.sts.filter.marketerId);
                }

                vm.ts.setData(vm.marketerCampaigns, 'marketerCampaigns', vm.sort['marketerCampaigns']);
                vm.ts.search('marketerCampaigns');
                $scope.updatePageData();
              });
            });
        } else {
          vm.audiences = rootVM.audiences;
          if (process) {
            vm.processUrl();
          }
          $scope.updatePageData();
        }
      }
      $rootScope.$on('audienceSaved', function () {
        vm.loadData(false, true);
      });
      $rootScope.$on('audienceUpdate', function (name, notification) {
        if (rootVM.audiences && rootVM.audiences.find(a => a.id == notification.value)) {
          logger.showToastPermanent('Lasso Notification: Audience List has been changed.');
          rootVM.audiences = null;
          $rootScope.$broadcast('audienceSaved');
        }
      });
      const index = vm.tabs.findIndex(t => $location.$$path.indexOf(t.location) > -1);
      vm.setActiveTab(index);
      if (index === 3 && !vm.getActiveAdminOnlyTab()) {
        vm.setActiveAdminOnlyTab(0);
      }

      vm.pageSize = vm.config.pageSize().toString();

      vm.pageSizeChanged = function (size) {
        vm.pageSize = size;
        vm.sts.pageSizeChanged(size);
      };

    }]);

  angular.module('app')
    .controller('editConversionPixelController', ['$scope', '$rootScope', '$uibModalInstance', '$sce', 'data', 'viewMode', 'hub', 'campaignService', 'validationService', '$timeout', '$location', 'authService', function ($scope, $rootScope, $modalInstance, $sce, data, viewMode, hub, campaignService, validationService, $timeout, $location, authService) {
      var vm = this;
      $scope.vm = vm;
      vm.viewMode = viewMode;
      vm.config = hub.config;
      vm.isObserverRole = authService.isObserverRole();
      vm.canEdit = (authService.isSuperAdmin() || authService.hasAccess('audiences', 'Manage')) && !vm.isObserverRole;

      vm.selectedAdvertiserChanged = function () {
        if (vm.selectedAdvertiser)
          vm.audience.advertiserId = vm.selectedAdvertiser.id
      }

      $scope.canSubmit = function () {
        var audienceValid = false;

        if (typeof this.audienceForm !== 'undefined')
          audienceValid = this.audienceForm.$valid && validationService.validateAudience(vm.audience);

        return audienceValid;
      };

      $scope.getDownloadUrl = function (id) {
        return hub.api.get('api/audience/download/' + id).then(function (r) {
          if (r.url) {
            var a = document.createElement('a');
            a.href = r.url;
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
          }
        });
      };

      $scope.ok = function () {
        var startTime = new Date();

        if (typeof this.audienceForm !== 'undefined') {
          var audienceValid = this.audienceForm.$valid && validationService.validateAudience(vm.audience);
          if (!audienceValid) {
            vm.audience.validation = true;
            $('#audienceForm').validator('validate');
            return;
          }
        } else
          return;

        if (vm.audience.headers)
          vm.audience.headers = _.map(vm.audience.headers, 'column');

        vm.isInProgress = true;
        vm.audience.audienceType = 'Conversion';
        hub.webapicontext.saveAudience(vm.audience).then(function () {
          vm.isInProgress = false;
          var data = { id: vm.audience.id, name: vm.audience.name };
          authService.trackEvent({
            action: 'SaveAudience',
            view: $location.$$path,
            data: JSON.stringify(data)
          }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
          $modalInstance.close(vm.audience);
          $rootScope.$broadcast('audienceSaved', vm.audience);
        });
      };


      $scope.cancel = function (cancelClicked) {
        vm.cancelClicked = cancelClicked;
        $modalInstance.dismiss('cancel');
      };

      vm.audience = angular.copy(data);

      campaignService.getAccounts(1).then(function () {
        vm.advertisers = campaignService.advertisers;
        if (vm.audience.advertiserId)
          vm.selectedAdvertiser = vm.advertisers.find(function (a) {
            return a.id === vm.audience.advertiserId;
          });
      });

      $scope.$on('modal.closing', function (event, reason) {
        if (!vm.showCaution && reason == 'cancel' && event.currentScope.audienceForm.$dirty && vm.cancelClicked) {
          $timeout(function () {
            vm.showCaution = true;
          });
          event.preventDefault();
        }
      });

      $scope.$on('modal.force.close', function () {
        $modalInstance.dismiss('cancel');
      });

    }]);

  angular.module('app')
    .controller('editAdminOnlyController', ['$scope', '$rootScope', '$uibModalInstance', '$sce', 'adminOnlyAudienceTypes', 'selectedAdminOnlyAudienceType', 'data', 'viewMode', 'hub', 'campaignService', 'validationService', '$timeout', '$location', 'authService', function ($scope, $rootScope, $modalInstance, $sce, adminOnlyAudienceTypes, selectedAdminOnlyAudienceType, data, viewMode, hub, campaignService, validationService, $timeout, $location, authService) {
      var vm = this;
      $scope.vm = vm;
      var log = hub.getLog('Audience Management');
      vm.log = log;
      vm.adminOnlyAudienceTypes = adminOnlyAudienceTypes;
      vm.viewMode = viewMode;
      vm.config = hub.config;
      vm.canEdit = authService.isSuperAdmin() || authService.hasAccess('audiences', 'Manage');
      vm.audienceTypes = campaignService.getAudienceTypes();
      vm.selectedAdminOnlyAudienceType = selectedAdminOnlyAudienceType;
      $timeout(function () {
        vm.selectedAdminOnlyAudienceTypeChanged();
      });

      $scope.canSubmit = function () {
        var audienceValid = false;

        if (typeof this.audienceForm !== 'undefined')
          audienceValid = this.audienceForm.$valid && validationService.validateAudience(vm.audience);

        return audienceValid;
      };

      $scope.ok = function () {
        var startTime = new Date();

        if (typeof this.audienceForm !== 'undefined') {
          var audienceValid = this.audienceForm.$valid && validationService.validateAudience(vm.audience);
          if (!audienceValid) {
            vm.audience.validation = true;
            vm.externalIdInvalid = document.getElementById('externalId').value.length > 10;
            $('#audienceForm').validator('validate');
            return;
          } else
            vm.externalIdInvalid = false;
        } else
          return;

        if ((vm.selectedAdminOnlyAudienceType.type === 'CMIAudience' || vm.selectedAdminOnlyAudienceType.type === 'CMIPROACTCampaign') && !vm.file)
          return;

        if (vm.audience.headers)
          vm.audience.headers = _.map(vm.audience.headers, 'column');

        vm.isInProgress = true;
        if (vm.selectedAdminOnlyAudienceType.type === 'CMIAudience' || vm.selectedAdminOnlyAudienceType.type === 'CMIPROACTCampaign') {
          vm.uploadCSV(vm.file, true)
          $modalInstance.close(vm.audience);
        } else if (vm.selectedAdminOnlyAudienceType.type === 'CMICampaign' || vm.selectedAdminOnlyAudienceType.type === 'ConneXion360') {
          var campaign = {
            marketerId: vm.audience.marketerId,
            brand: vm.audience.brand,
            contract: vm.audience.contract,
            projectId: vm.audience.projectId,
            campaignId: vm.selectedCampaign.campaignId
          };
          hub.webapicontext.saveMarketerCampaign(campaign).then(function () {
            if (vm.selectedAdminOnlyAudienceType.type === 'CMICampaign')
              vm.log("CMI Campaign successfully created!");
            else
              vm.log("ConneXion360 Campaign successfully created!");

            $modalInstance.close(vm.audience);
          });
        } else {
          vm.audience.isAdminOnly = true;
          hub.webapicontext.saveAudience(vm.audience).then(function () {
            vm.isInProgress = false;
            var data = { id: vm.audience.id, name: vm.audience.name };
            authService.trackEvent({
              action: 'SaveAudience',
              view: $location.$$path,
              data: JSON.stringify(data)
            }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
            $modalInstance.close(vm.audience);
            $rootScope.$broadcast('audienceSaved', vm.audience);
          });
        }
      };


      $scope.cancel = function (cancelClicked) {
        vm.cancelClicked = cancelClicked;
        $modalInstance.dismiss('cancel');
      };

      vm.audience = angular.copy(data);
      vm.audience.headerRowsNumber = vm.audience.id ? vm.audience.headerRowsNumber : 1;

      if (vm.audience.audienceType)
        vm.selectedAudienceType = vm.audienceTypes.find(function (at) {
          return at.type == vm.audience.audienceType;
        });

      campaignService.getAccounts(1).then(function () {
        vm.marketers = campaignService.marketers;
        vm.advertisers = campaignService.advertisers;

        vm.selectedMarketer = vm.marketers.find(function (m) {
          return m.id == vm.audience.marketerId;
        });
        vm.selectedAdvertiser = vm.advertisers.find(function (m) {
          return m.id == vm.audience.advertiserId;
        });
      });

      campaignService.getCampaignsList().then(function () {
        vm.campaigns = _.orderBy(campaignService.campaigns, 'name');
        vm.filteredCampaigns = null;
        if (vm.selectedAdvertiser) {
          vm.audience.advertiserId = vm.selectedAdvertiser.id;
          vm.filteredCampaigns = _.orderBy(vm.campaigns.filter(function (c) {
            return c.advertiserId == vm.audience.advertiserId;
          }), 'name');
        }

        if (vm.audience.campaignId)
          vm.selectedCampaign = vm.campaigns.find(function (c) {
            return c.campaignId == vm.audience.campaignId;
          });
      });

      vm.selectedAdminOnlyAudienceTypeChanged = function () {
        if (vm.selectedAdminOnlyAudienceType) {
          if (vm.selectedAdminOnlyAudienceType.type !== 'ExternalAudience') {
            var marketerId = vm.selectedAdminOnlyAudienceType.type === 'CMIAudience' || vm.selectedAdminOnlyAudienceType.type === 'CMICampaign' || vm.selectedAdminOnlyAudienceType.type === 'CMIPROACTCampaign' ? 49 : 78;
            vm.selectedMarketer = vm.marketers.find(function (m) {
              return m.id === marketerId;
            });
            vm.selectedMarketerChanged();
          } else {
            vm.audience.marketerId = null;
            vm.selectedMarketer = null;
          }
        }
        vm.csvUploaded = false;
      };

      vm.selectedMarketerChanged = function () {
        if (vm.selectedMarketer) {
          vm.audience.marketerId = vm.selectedMarketer.id;
          vm.advertisers = campaignService.advertisers.filter(function (a) {
            return a.marketerId == vm.audience.marketerId;
          });
          if (vm.campaigns)
            vm.filteredCampaigns = _.orderBy(vm.campaigns.filter(function (c) {
              return c.marketerId == vm.audience.marketerId;
            }), 'name');
        }
      };

      vm.selectedAdvertiserChanged = function () {
        if (vm.selectedAdvertiser) {
          vm.audience.advertiserId = vm.selectedAdvertiser.id;
          if (vm.campaigns)
            vm.filteredCampaigns = _.orderBy(vm.campaigns.filter(function (c) {
              return c.advertiserId == vm.audience.advertiserId;
            }), 'name');
        }
      };

      vm.selectedCampaignChanged = function () {
        if (vm.selectedCampaign)
          vm.audience.campaignId = vm.selectedCampaign.campaignId;
      }

      vm.selectedAudienceTypeChanged = function () {
        if (vm.selectedAudienceType)
          vm.audience.audienceType = vm.selectedAudienceType.name;
      }

      vm.uploadCSV = function (file, isSave) {
        if (!isSave)
          vm.file = file;
        return hub.webapicontext.uploadAdminOnlyFile('Audience/AdminOnlyFileUpload', vm.file.file, vm.selectedAdminOnlyAudienceType.type, vm.audience.advertiserId, isSave, vm.audience.campaignId).then(function (data) {
          if (data.data) {
            if (!isSave) {
              vm.audience.originalFileName = data.data.originalFileName;
              vm.audience.dataFileUrl = hub.settings.audienceDataUrl + data.data.filename;
              vm.fileType = file.type;
              vm.uploadedData = file.data;
              vm.noPreview = data.data.noPreview;
              if (!vm.noPreview) {
                const { filePreviewJSON, headersJSON } = data.data;
                vm.serverData = { headers: JSON.parse(headersJSON), filePreview: JSON.parse(filePreviewJSON) };
                readCsv(file.data);
              }
              vm.audience.name = data.data.originalFileName.replace('.csv', '');
            } else {
              vm.log("CMI Audience " + vm.audience.name + " successfully uploaded!");
            }
          }
        });
      };

      function readCsv(data) {
        if (data) {
          var cells = data.split("\n").filter(function (r) {
            return hub.page.csvToArray(r);
          }).map(function (row) {
            return row ? hub.page.csvToArray(row).map($.trim) : null;
          });

          var headers = [];

          for (var i = 0; i < vm.audience.headerRowsNumber; i++)
            Array.prototype.push.apply(headers, cells[i]);

          headers = headers.filter(function (h) {
            return h && h !== '' && h !== ' ';
          });
          if (headers.length === 0) {
            vm.log("Please upload correct CSV file.");
            vm.audience.originalFileName = null;
            vm.audience.dataFileUrl = null;
            vm.uploadedData = null;
            return;
          }
          vm.audience.headers = vm.serverData.headers;
          vm.filePreview = vm.serverData.filePreview;

          if (!vm.audience.headerRowsNumber) {
            vm.audience.headers = [];
            vm.filePreview = [];
            var n = 0;
            headers.forEach(function () {
              var field = n++;
              vm.audience.headers.push({ name: field, originalColumn: field });
            });
            for (var i = 0; i < 10; i++) {
              var res = {};
              for (var j = 0; j < headers.length; j++) {
                res[j] = cells[i][0].split(',')[j];
              }
              vm.filePreview.push(res);
            }
          }

          $timeout(function () {
            vm.csvUploaded = true;
          });
        }
      }


      $scope.$on('modal.closing', function (event, reason) {
        if (!vm.showCaution && reason == 'cancel' && event.currentScope.audienceForm.$dirty && vm.cancelClicked) {
          $timeout(function () {
            vm.showCaution = true;
          });
          event.preventDefault();
        }
      });

      $scope.$on('modal.force.close', function () {
        $modalInstance.dismiss('cancel');
      });

    }]);

  angular.module('app')
    .controller('editRetargetingAudienceController', ['$scope', '$rootScope', '$uibModalInstance', '$sce', 'data', 'viewMode', 'hub', 'campaignService', 'validationService', '$timeout', '$location', 'authService', function ($scope, $rootScope, $modalInstance, $sce, data, viewMode, hub, campaignService, validationService, $timeout, $location, authService) {
      var vm = this;
      $scope.vm = vm;
      vm.viewMode = viewMode;
      vm.config = hub.config;
      vm.isObserverRole = authService.isObserverRole();
      vm.canEdit = (authService.isSuperAdmin() || authService.hasAccess('audiences', 'Manage')) && !vm.isObserverRole;

      vm.selectedMarketerChanged = function () {
        if (vm.selectedMarketer)
          vm.advertisers = _.orderBy(campaignService.advertisers.filter(function (a) {
            return a.marketerId == vm.selectedMarketer.id;
          }), 'name');

        vm.selectedAdvertiser = undefined;
        vm.selectedCampaign = undefined;
        vm.selectedAdGroup = undefined;
        vm.selectedCampaignChanged();
      };

      vm.selectedAdvertiserChanged = function () {
        if (vm.selectedAdvertiser)
          campaignService.getCampaignNames(vm.selectedAdvertiser.id).then(function (data) {
            vm.campaigns = data;
          });

        vm.selectedCampaign = undefined;
        vm.selectedAdGroup = undefined;
        vm.selectedCampaignChanged();
      };

      vm.selectedCampaignChanged = function () {
        var marketerId = '';
        if (vm.selectedMarketer)
          marketerId = vm.selectedMarketer.id;

        var advertiserId = '';
        if (vm.selectedAdvertiser)
          advertiserId = vm.selectedAdvertiser.id;

        var campaignId = '';
        if (vm.selectedCampaign)
          campaignId = vm.selectedCampaign.campaignId;

        var keyword = '';
        if (vm.keyword)
          keyword = vm.keyword;

        if (marketerId || advertiserId || campaignId || keyword)
          campaignService.getAdGroupNames(marketerId, advertiserId, campaignId, keyword).then(function (data) {
            vm.adGroups = data;
          });

        vm.selectedAdGroup = undefined;
      };

      vm.selectedAdGroupChanged = function () {
        if (vm.selectedAdGroup) {
          vm.audience.retargetingAdGroupId = vm.selectedAdGroup.adGroupId;
          vm.audience.advertiserId = vm.selectedAdGroup.advertiserId;
          vm.audience.marketerId = vm.selectedAdGroup.marketerId;
        }
      }

      $scope.canSubmit = function () {
        var audienceValid = false;

        if (typeof this.audienceForm !== 'undefined')
          audienceValid = this.audienceForm.$valid && validationService.validateAudience(vm.audience);

        return audienceValid;
      };

      $scope.ok = function () {
        var startTime = new Date();

        if (typeof this.audienceForm !== 'undefined') {
          var audienceValid = this.audienceForm.$valid && validationService.validateAudience(vm.audience);
          if (!audienceValid) {
            vm.audience.validation = true;
            $('#audienceForm').validator('validate');
            return;
          }
        } else
          return;

        vm.isInProgress = true;
        vm.audience.audienceType = 'retargeting';
        hub.webapicontext.saveAudience(vm.audience).then(function () {
          vm.isInProgress = false;
          var data = { id: vm.audience.id, name: vm.audience.name };
          authService.trackEvent({
            action: 'SaveAudience',
            view: $location.$$path,
            data: JSON.stringify(data)
          }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
          $modalInstance.close(vm.audience);
          $rootScope.$broadcast('audienceSaved', vm.audience);
        });
      };


      $scope.cancel = function (cancelClicked) {
        vm.cancelClicked = cancelClicked;
        $modalInstance.dismiss('cancel');
      };

      vm.audience = angular.copy(data);

      if (vm.audience.id) {
        campaignService.getAdGroupNames('', '', '', vm.audience.retargetingAdGroupId, true).then(function (data) {
          if (data && data.length > 0) {
            vm.adGroups = data;
            vm.selectedAdGroup = data.find(function (ag) {
              return ag.adGroupId == vm.audience.retargetingAdGroupId;
            });
            vm.audience.retargetingAction = vm.selectedAdGroup.retargetingAction;
          }
        });
      }

      campaignService.getAccounts().then(function () {
        vm.marketers = _.orderBy(campaignService.marketers, 'name');
        vm.advertisers = _.orderBy(campaignService.advertisers, 'name');
      });

      $scope.$on('modal.closing', function (event, reason) {
        if (!vm.showCaution && reason == 'cancel' && event.currentScope.audienceForm.$dirty && vm.cancelClicked) {
          $timeout(function () {
            vm.showCaution = true;
          });
          event.preventDefault();
        }
      });

      $scope.$on('modal.force.close', function (event) {
        $modalInstance.dismiss('cancel');
      });

    }]);

  angular.module('app')
    .controller('pushAudienceController', ['$scope', '$rootScope', '$uibModalInstance', '$sce', 'data', 'hub', 'campaignService', 'validationService', '$timeout', '$location', 'authService', function ($scope, $rootScope, $modalInstance, $sce, data, hub, campaignService, validationService, $timeout, $location, authService) {
      var vm = this;
      $scope.vm = vm;

      $scope.ok = function () {
        var startTime = new Date();

        vm.isInProgress = true;
        hub.webapicontext.pushDmpAudience(vm.audience).then(function () {
          vm.isInProgress = false;
          var data = { id: vm.audience.id, name: vm.audience.name };
          authService.trackEvent({
            action: 'PushAudience',
            view: $location.$$path,
            data: JSON.stringify(data)
          }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
          $modalInstance.close(vm.audience);
          $rootScope.$broadcast('audienceSaved', vm.audience);
        }, function (data) {
          vm.isInProgress = false;
          $modalInstance.close(data);
        });
      };


      $scope.cancel = function (cancelClicked) {
        vm.cancelClicked = cancelClicked;
        $modalInstance.dismiss('cancel');
      };

      vm.audience = angular.copy(data);
      vm.audience.customName = 'Lasso > ';

      $scope.$on('modal.force.close', function (event) {
        $modalInstance.dismiss('cancel');
      });

    }]);

})();
