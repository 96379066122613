import angular from "angular";
import "angular-route";
import angularDragula from "angularjs-dragula";
import ngSanitize from "angular-sanitize";
import ngFileUpload from "ng-file-upload";

// this is custom packages
import "./vendors/ui-bootstrap.custom";
import "angular-bootstrap-toggle";
import flow from "@flowjs/ng-flow/dist/ng-flow-standalone";
import ngCsv from "ng-csv";
import xeditable from "angular-xeditable";
import angularGrid from "angulargrid";
import "ng-jwplayer/jwplayer";
import "ez-plus";
import "angular-ez-plus/js/angular-ezplus";
import blockUI from "angular-block-ui";
import rzSlider from "angularjs-slider";
import "angular-local-storage";
import "bootstrap/dist/js/bootstrap.min.js";
import _ from "lodash";
import moment from 'moment-timezone'
import template from "@views/app/app.html?raw";
import am4Init from './vendors/am4Init';

window.moment = moment
window._ = _
window.Flow = flow;
am4Init()

;(function () {
  "use strict";

  //test
  angular
    .module("app", [
      "ngRoute",
      ngSanitize,
      ngFileUpload,
      "ui.bootstrap",
      "ui.toggle",
      "app.page",
      "flow",
      "LocalStorageModule",
      ngCsv,
      xeditable,
      angularGrid,
      "ng-jwplayer",
      "ezplus",
      blockUI,
      rzSlider,
      angularDragula(angular),
    ])
    .constant("_", _);
})();

angular.module("app").config([
  "$uibModalProvider",
  function (config) {
    config.options.appendTo = angular.element(document).find("#angular-modals");
  },
]);

angular.module("app").config([
  "$httpProvider",
  "$locationProvider",
  "$qProvider",
  function ($httpProvider, $locationProvider, $qProvider) {
    $httpProvider.interceptors.push("authInterceptorService");
    $locationProvider.hashPrefix("");
    $qProvider.errorOnUnhandledRejections(false);
    $locationProvider.html5Mode({
      enabled: true,
      requireBase: false,
    });
  },
]);

angular.module("app").component("root", {
  template,
  controller: "AppCtrl",
});

angular.module("app").run([
  "authService",
  "storagecontext",
  "signalR",
  "resourceService",
  "$rootScope",
  "logger",
  "editableOptions",
  "$injector",
  "$location",
  "$timeout",
  function (
    authService,
    storagecontext,
    signalR,
    resourceService,
    $rootScope,
    logger,
    editableOptions,
    $injector,
  ) {
    editableOptions.theme = "bs3";
    authService.fillAuthData();
    resourceService.load();
    authService.getUserInfo().then(
      function (d) {

        if (!$rootScope.rootVM) $rootScope.rootVM = {};

        $rootScope.rootVM.isLoaded = true;
        $rootScope.rootVM.accountId = d.accountId;
      },
      function () {
        $rootScope.rootVM.isLoaded = true;
        logger.logError("Unable to get user details", "", "ProDataRX", false);
      }
    );
    var blockUIConfig = $injector.get("blockUIConfig");
    blockUIConfig.autoBlock = false;
  },
]);

angular.module("app").run([
  "$templateCache",
  function ($templateCache) {
    $templateCache.put(
      "views/campaign/editors/campaign.html",
      require("@views/campaign/editors/campaign.html").default
    );
    $templateCache.put(
      "views/campaign/editors/ad-group.html",
      require("@views/campaign/editors/ad-group.html").default
    );
    $templateCache.put(
      "views/campaign/editors/ad-group-complex-editor.html",
      require("@views/campaign/editors/ad-group-complex-editor.html").default
    );
    $templateCache.put(
      "views/campaign/editors/ad-group-copy-edit.html",
      require("@views/campaign/editors/ad-group-copy-edit.html").default
    );
    $templateCache.put(
      "views/campaign/editors/split-editor.html",
      require("@views/campaign/editors/split-editor.html").default
    );
    $templateCache.put(
      "views/creative/editors/creative-zoom.html",
      require("@views/creative/editors/creative-zoom.html").default
    );
    $templateCache.put(
      "views/creative/editors/creative.html",
      require("@views/creative/editors/creative.html").default
    );
    $templateCache.put(
      "views/creative/editors/bulk-creative-upload.html",
      require("@views/creative/editors/bulk-creative-upload.html").default
    );
    $templateCache.put(
      "views/creative/editors/bulk-creative-resend.html",
      require("@views/creative/editors/bulk-creative-resend.html").default
    );
    $templateCache.put(
      "views/creative/editors/creative-copy.html",
      require("@views/creative/editors/creative-copy.html").default
    );
    $templateCache.put(
      "views/app/dialog.html",
      require("@views/app/dialog.html").default
    );

    $templateCache.put(
      "views/admin/account.html",
      require("@views/admin/account.html").default
    );
    $templateCache.put(
      "views/admin/users.directive.html",
      require("@views/admin/users.directive.html").default
    );
    $templateCache.put(
      "views/admin/user.html",
      require("@views/admin/user.html").default
    );

    $templateCache.put(
      "views/audience/audience.html",
      require("@views/audience/audience.html").default
    );
    $templateCache.put(
      "views/audience/editors/admin-only-editor.html",
      require("@views/audience/editors/admin-only-editor.html").default
    );
    $templateCache.put(
      "views/sidebar.html",
      require("@views/sidebar.html").default
    );
    $templateCache.put(
      "views/campaign/campaign-wizard.html",
      require("@views/campaign/campaign-wizard.html").default
    );
    $templateCache.put(
      "views/campaign/campaign.html",
      require("@views/campaign/campaign.html").default
    );
    $templateCache.put(
      "views/campaign/editors/bulk-campaign-resend.html",
      require("@views/campaign/editors/bulk-campaign-resend.html").default
    );
    $templateCache.put(
      "views/campaign/editors/ad-group-editor.html",
      require("@views/campaign/editors/ad-group-editor.html").default
    );
    $templateCache.put(
      "views/creative/creative.html",
      require("@views/creative/creative.html").default
    );
    $templateCache.put(
      "views/creative-request/creative-request.html",
      require("@views/creative-request/creative-request.html").default
    );
    $templateCache.put(
      "views/reporting/custom.html",
      require("@views/reporting/custom.html").default
    );
    $templateCache.put(
      "views/reporting/list.html",
      require("@views/reporting/list.html").default
    );
    $templateCache.put(
      "views/reporting/match.html",
      require("@views/reporting/match.html").default
    );
    $templateCache.put(
      "views/reporting/campaign-summary-tabs/reach.html",
      require("@views/reporting/campaign-summary-tabs/reach.html").default
    );
    $templateCache.put(
      "views/reporting/campaign-summary-tabs/engagement.html",
      require("@views/reporting/campaign-summary-tabs/engagement.html").default
    );
    $templateCache.put(
      "views/reporting/campaign-summary-tabs/performanceDetails.html",
      require("@views/reporting/campaign-summary-tabs/performanceDetails.html")
        .default
    );
    $templateCache.put(
      "views/reporting/campaign-summary-tabs/creatveDetails.html",
      require("@views/reporting/campaign-summary-tabs/creatveDetails.html")
        .default
    );
    $templateCache.put(
      "views/pages/404.html",
      require("@views/pages/404.html").default
    );
    $templateCache.put(
      "views/pages/403.html",
      require("@views/pages/403.html").default
    );
    $templateCache.put(
      "views/pages/500.html",
      require("@views/pages/500.html").default
    );
    $templateCache.put(
      "views/pages/change-password.html",
      require("@views/pages/change-password.html").default
    );
    $templateCache.put(
      "views/admin/users.html",
      require("@views/admin/users.html").default
    );
    $templateCache.put(
      "views/admin/account-management.html",
      require("@views/admin/account-management.html").default
    );
    $templateCache.put(
      "views/settings/account-settings.html",
      require("@views/settings/account-settings.html").default
    );
    $templateCache.put(
      "views/brand-safety/brand-safety.html",
      require("@views/brand-safety/brand-safety.html").default
    );
    $templateCache.put(
      "views/admin/features.html",
      require("@views/admin/features.html").default
    );
    $templateCache.put(
      "views/admin/roles.html",
      require("@views/admin/roles.html").default
    );
    $templateCache.put(
      "views/app/flight-progress.html",
      require("@views/app/flight-progress.html").default
    );
    $templateCache.put("views/v2.html", require("@views/v2.html").default);
    $templateCache.put(
      "views/location/location.html",
      require("@views/location/location.html").default
    );
    $templateCache.put(
      "views/location/location-editor.html",
      require("@views/location/location-editor.html").default
    );
    $templateCache.put(
      "views/location/new-location-editor.html",
      require("@views/location/new-location-editor.html").default
    );
    $templateCache.put(
      "views/campaign/editors/bulk-ad-group-upload.html",
      require("@views/campaign/editors/bulk-ad-group-upload.html").default
    );
    $templateCache.put(
      "views/campaign/history.html",
      require("@views/campaign/history.html").default
    );
    $templateCache.put(
      "views/audience/editors/push-audience.html",
      require("@views/audience/editors/push-audience.html").default
    );
    $templateCache.put(
      "views/audience/editors/deal-editor.html",
      require("@views/audience/editors/deal-editor.html").default
    );
    $templateCache.put(
      "views/audience/editors/pixel-editor.html",
      require("@views/audience/editors/pixel-editor.html").default
    );
    $templateCache.put(
      "views/audience/editors/retargeting-audience-editor.html",
      require("@views/audience/editors/retargeting-audience-editor.html")
        .default
    );
    $templateCache.put(
      "views/brand-safety/editor/inventory-list.html",
      require("@views/brand-safety/editor/inventory-list.html").default
    );
    $templateCache.put(
      "views/brand-safety/editor/keyword-list.html",
      require("@views/brand-safety/editor/keyword-list.html").default
    );
    $templateCache.put(
      "views/brand-safety/editor/blocking-profile.html",
      require("@views/brand-safety/editor/blocking-profile.html").default
    );
    $templateCache.put(
      "views/reporting/schedule.html",
      require("@views/reporting/schedule.html").default
    );
    $templateCache.put(
      "views/creative/editors/creative-versions.html",
      require("@views/creative/editors/creative-versions.html").default
    );
  },
]);

angular.module('app')
  .directive('mwlConfirm', function() {

    return {
      restrict: 'A',
      controller:  function($scope, $rootScope, $element, $attrs, $compile, $document, $window, $timeout,
				$injector, $templateRequest, $parse, $log, $animate,dialogService) {
        var vm = this;
        function evaluateOuterScopeValue(scopeName, defaultValue, locals) {
          if (angular.isDefined(scopeName)) {
            return $parse(scopeName)($scope, locals);
          } else {
            return defaultValue;
          }
        }
        vm.onConfirm = function(callbackLocals) {
          evaluateOuterScopeValue($attrs.onConfirm, null, callbackLocals);
        };

        vm.onCancel = function(callbackLocals) {
          evaluateOuterScopeValue($attrs.onCancel, null, callbackLocals);
        };
			  $element.on('click', function() {
          var modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Remove',
            message: $element.attr('message'),
          };

          dialogService.showModal({ windowClass: 'modal-dialog-alert' }, modalOptions).
            then(function (result) {
                console.log('result', result);
                vm.onConfirm()
            })
            .catch(function (error) {
                console.log('error', error)
            })
        });
		  }
	}
  });
